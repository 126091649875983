






import Vue from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import Waypoint from "@/types/Waypoint";
import { createWaypointTemplate } from '../../utility/waypointTemplate';
import environment from '@/environment';

const mapOptions: google.maps.MapOptions = {
  center: { lat: 57.6907, lng: -2.0031 },
  zoom: 8,
  zoomControl: false,
  keyboardShortcuts: false,
  mapId: "e3c6a69fbbdfab35",
};

interface Data {
  map: google.maps.Map | undefined
}

export default Vue.extend({
  props: ["entries"],
  data() : Data {
    return {
      map: undefined,
    }
  },
  computed: {
    waypoints() : Waypoint[] {
      return this.entries;
    }
  },
  async mounted() {
    this.map = await this.loadGoogleMaps();
  },
  watch: {
    "entries": {
      async handler() {
        await this.loadGoogleMaps();
      }
    }
  },
  methods: {
    async loadGoogleMaps(): Promise<google.maps.Map | undefined> {
      const loader = new Loader({
        apiKey: environment.GOOGLE_MAPS_API_KEY,
        version: "weekly",
      });

      try {
        const mapContainer = this.$refs.googleMap;

        const { AdvancedMarkerElement, PinElement } = await loader.importLibrary("marker");
        await loader.importLibrary("places");
        const { Map } = await loader.importLibrary("maps");

        const map = new Map(mapContainer as HTMLElement, mapOptions);

        this.waypoints.forEach(waypoint => {
          const content = document.createElement("div");
          content.classList.add("property");

          content.innerHTML = createWaypointTemplate(waypoint.categoryDetails.iconIdentifier, waypoint.waypointName, waypoint.categoryDetails.name, waypoint.emergencyContact);

          const marker = new AdvancedMarkerElement({
            position: {
              lat: waypoint.latitude,
              lng: waypoint.longitude
            },
            map: map,
            title: waypoint.waypointName,
            content: content
          });

          marker.addListener("click", () => {
            this.toggleHighlight(marker);
          });
        })

        return map;
      } catch (error) {
        console.error("Unable to fetch Google Maps", error);
      }
    },
    toggleHighlight(markerView: any) {
      if (markerView.content.classList.contains("highlight")) {
        markerView.content.classList.remove("highlight");
        markerView.zIndex = null;
      } else {
        markerView.content.classList.add("highlight");
        markerView.zIndex = 1;
      }
    }
  }
});
