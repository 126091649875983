var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{attrs:{"noCard":true,"noCreate":true,"noSearch":true,"items":_vm.waypoints,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.categoryDetails.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi "+_vm._s(item.categoryDetails.iconIdentifier))]),_vm._v(" "+_vm._s(item.categoryDetails.name)+" ")]}},{key:"item.latitude",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.latitude.toFixed(7))+" ")]}},{key:"item.longitude",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.longitude.toFixed(7))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit-item', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('waypoint-delete', item.id)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }