























































































































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { WaypointCategory, WaypointCreateModel } from "@/types/Waypoint";
import Waypoint from "../../types/Waypoint";
import { Loader } from "@googlemaps/js-api-loader";
import environment from "@/environment";

@Component
export default class WaypointCreateDialog extends Validation {
  @Ref() private readonly form!: VForm;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop() editModel: Waypoint | undefined;
  @Prop() categories!: WaypointCategory[];

  public model: WaypointCreateModel = {
    isGlobal: true,
    isHelp: false,
    waypointName: "",
    waypointDescription: "",
    waypointCategory: 0,
    latitude: 0,
    longitude: 0,
  };

  public isWelfare = false;

  public onAddWaypointCreate() {
    if (!this.form.validate()) {
      return;
    }

    if (this.editModel) {
      // Edit
      this.$emit("waypoint-edited", this.model);
    } else {
      // Create
      this.$emit("waypoint-added", this.model);
    }
  }

  @Watch("syncedDialog")
  public onDialogVisibleChange(value: boolean) {
    if (value) {
      if (!this.editModel) this.form?.reset();

      this.loadAutocomplete();

      if (this.editModel) {
        this.model = {
          ...this.editModel,
          emergencyContactCreateModel: JSON.parse(
            JSON.stringify(this.editModel.emergencyContact)
          ),
          isGlobal: true,
        };
        if (this.editModel.isHelp) {
          this.model.isHelp = true;
        }

        if (this.editModel.emergencyContact) this.isWelfare = true;
      }
    }
  }

  @Watch("isWelfare", { immediate: false })
  public onIsWelfareChanged(value: boolean) {
    if (value && this.model.emergencyContactCreateModel) return;

    if (value) {
      this.model.emergencyContactCreateModel = {
        id: undefined,
        forename: "",
        surname: "",
        address: "",
        emailAddress: "",
        phoneNumber: "",
      };
      this.model.isHelp = true;
    } else {
      this.model.emergencyContactCreateModel = undefined;
    }
  }

  public async loadAutocomplete() {
    const loader = new Loader({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });

    const places = await loader.importLibrary("places");

    const autocompleteOptions = {
      fields: ["formatted_address", "geometry", "name", "icon", "types"],
      strictBounds: false,
    };

    const autocomplete = new places.Autocomplete(
      this.$refs["placesAutocomplete"] as HTMLInputElement,
      autocompleteOptions
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      this.model.latitude = place.geometry?.location?.lat()!;
      this.model.longitude = place.geometry?.location?.lng()!;
    });
  }
}
