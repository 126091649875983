import { EmergencyContact } from '../types/Waypoint';

const renderEmergencyContact = (emergencyContact?: EmergencyContact) => {
  if (emergencyContact) {
    return `
      <div style="width: 200px; text-align: right; font-size: 14px;">
        <h5>Welfare Info</h5> <br/>
        ${emergencyContact.forename} ${emergencyContact.surname} <br/>
        ${emergencyContact.address} <br/><br/>
        <a href="mailto:${emergencyContact.emailAddress}">${emergencyContact.emailAddress}</a> <br/><br/>
        <a href="tel:${emergencyContact.phoneNumber}">${emergencyContact.phoneNumber}</a>
      </div>
    `;
  } else {
    return "";
  }
}

export const createWaypointTemplate = (icon: string, name: string, categoryName: string, emergencyContact?: EmergencyContact) => `
    <div class="icon">
        <span class="mdi ${icon}"></span>
    </div>
    <div class="details" style="text-align: left; width: 200px;">
      ${name}

      ${renderEmergencyContact(emergencyContact)}
    </div>
`;