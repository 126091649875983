

















































import Waypoint from "@/types/Waypoint";
import nameof from "@/utility/nameof";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import momentUtility from "@/utility/momentUtility";

const headers: Array<DataTableHeader<Waypoint>> = [
  { text: "Name", value: nameof<Waypoint>("waypointName") },
  { text: "Category", value: "categoryDetails.name" },
  { text: "Description", value: nameof<Waypoint>("waypointDescription") },
  { text: "Latitude", value: nameof<Waypoint>("latitude") },
  { text: "Longitude", value: nameof<Waypoint>("longitude") },
  { text: "Created", value: nameof<Waypoint>("createdAt") },
  { text: "Actions", value: "actions" },
];

interface Data {
  headers: Array<DataTableHeader<Waypoint>>;
}

export default Vue.extend({
  props: ["entries"],
  data(): Data {
    return {
      headers,
    };
  },
  computed: {
    waypoints(): Waypoint[] {
      return this.entries;
    },
  },
  methods: {
    formatDate: momentUtility.formatDate,
  },
});
