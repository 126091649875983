




















































import Vue from "vue";
import WaypointMap from "@/components/mobile/WaypointMap.vue";
import WaypointList from "@/components/mobile/WaypointList.vue";
import { appApiClient } from "@/services/apiService";
import SnackbarModule from "../../store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Waypoint, { WaypointCreateModel } from "@/types/Waypoint";
import { WaypointCategory } from "../../types/Waypoint";
import WaypointCreateDialog from "../../components/dialogs/WaypointCreateDialog.vue";
const snackbarModule = getModule(SnackbarModule);

enum ActiveTab {
  MAP = 0,
  LIST = 1,
}

interface Data {
  activeTab: ActiveTab;
  waypoints: Waypoint[];
  isCreateDialogVisible: boolean;
  categories: WaypointCategory[];
  focussedWaypoint?: Waypoint;
}

export default Vue.extend({
  components: {
    WaypointMap,
    WaypointList,
    WaypointCreateDialog,
  },
  data(): Data {
    return {
      activeTab: ActiveTab.MAP,
      waypoints: [],
      isCreateDialogVisible: false,
      categories: [],
      focussedWaypoint: undefined,
    };
  },
  computed: {
    isMapOpen(): boolean {
      return this.$data.activeTab === ActiveTab.MAP;
    },
    parsedWaypoints(): Waypoint[] {
      return this.waypoints.map((waypoint) => {
        waypoint.categoryDetails = this.categories.find(
          (cat: WaypointCategory) => cat.category === waypoint.waypointCategory
        )!;

        return waypoint;
      });
    },
  },
  async mounted() {
    const categoriesResponse: WaypointCategory[] = (
      await appApiClient.get("/api/Waypoint/GetCategories")
    ).data;

    this.categories = categoriesResponse;

    await this.getWaypoints();
  },
  methods: {
    async getWaypoints() {
      try {
        const waypoints: Waypoint[] = (
          await appApiClient.get("/api/Waypoint/GetAllWaypoints/true/true")
        ).data;

        this.waypoints = waypoints;
      } catch (error) {
        snackbarModule.setSnackbarMessage("Failed to get waypoints.");
      }
    },
    async onWaypointCreate(createModel: WaypointCreateModel) {
      try {
        const { data } = await appApiClient.post(
          "/api/Waypoint/AddWaypoint",
          createModel
        );

        this.waypoints.push(data);
      } catch (error) {
        snackbarModule.setSnackbarMessage("Failed to add waypoint.");
      } finally {
        this.isCreateDialogVisible = false;
      }
    },
    async onDeleteWaypoint(id: number) {
      try {
        await appApiClient.delete(`/api/Waypoint/DeleteWaypoint/${id}`);

        this.waypoints = this.waypoints.filter(
          (waypoint) => waypoint.id !== id
        );
      } catch (error) {
        snackbarModule.setSnackbarMessage("Failed to delete waypoint.");
      }
    },
    onEditWaypoint(waypoint: Waypoint) {
      this.focussedWaypoint = waypoint;

      this.isCreateDialogVisible = true;
    },
    async onWaypointEdited(model: Waypoint) {
      try {
        const { data } = await appApiClient.put(
          "/api/Waypoint/UpdateWaypoint",
          model
        );

        this.waypoints = this.waypoints.map((waypoint) => {
          if (waypoint.id === (data as Waypoint).id) return data;

          return waypoint;
        });
      } catch (error) {
        snackbarModule.setSnackbarMessage("Failed to update waypoint.");
      } finally {
        this.isCreateDialogVisible = false;
      }
    },
  },
});
